/* ==============
  Form Wizard
===================*/
.form-wizard-wrapper {
  label {
    font-size: 14px;
    text-align: right;
  }
}

.wizard{
  // step
  >.steps {
    a, a:active, a:hover {
      margin: 3px;
      padding: 15px;
    }

    .current {
      a, a:active, a:hover {
        background-color: $primary;
        color: $white;
        .number {
          border: 2px solid $white
        }
      }
    }

    .disabled, .done {
      a, a:active, a:hover {
        background-color: lighten($primary,30%);
        color: $primary;
        .number {
          border-color: $primary;
        }
      }
    }

    .number {
      font-size: 16px;
      padding: 5px;
      border-radius: 50%;
      border: 2px solid $white;
      width: 38px;
      display: inline-block;
      font-weight: 500;
      text-align: center;
      margin-right: 10px;
      background-color: rgba($primary, 0.25);
    }
  }

  // content
  >.content {
    background-color: transparent;
    margin: 0 5px;
    border-radius: 0;
    min-height: 150px;
    >.body {
      width: 100%;
      height: 100%;
      padding: 30px 0 0;
      position: static
    }
  }

  // actions

  >.actions {
    a, a:active, a:hover {
      background-color: $primary;
      border-radius: 4px;
      padding: 8px 15px;
    }

    
    .disabled {
      a, a:active, a:hover {
        opacity: .65;
        background-color: $primary;
        color: $white;
        cursor: not-allowed;
      }
    }
  }
}


@media (max-width: 768px) {
  .wizard {
    > .steps {
      > ul {
        > li {
          width: 50%;
        }
      }
    }
  }
  .form-wizard-wrapper {
    label {
      text-align: left;
    }
  }
}

@media (max-width: 520px) {
  .wizard {
    > .steps {
      > ul {
        > li {
          width: 100%;
        }
      }
    }
  }
}
