
/* ==============
    Pricing
===================*/

.plan-box{
    .plan-icon{
        position: relative;

        &:before{
            content: "";
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            height: 2px;
            background: $gray-200;
        }
    }
}


