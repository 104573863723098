

/* ==============
  Account Pages
===================*/

.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 9;
}


.account-page-full-height {
  height: 100vh;
}

@media (max-width: 1199px){
  .account-page-full-height {
    height: auto;
  }
  .account-process{
    &:before{
      display: none
    }
  }
}


.account-process{
  position: relative;
  &:before{
    content: "";
    position: absolute;
    top: 50px;
    left: 50%;
    height: 2px;
    width: 100%;
    border-top: 1px dashed $white;
  }
}



