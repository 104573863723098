/* ==============
  projects
===================*/



.project-table{
    .team{
        .team-member{
            img{
                margin: 4px;
            }
        }
    }
}

