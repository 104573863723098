/* ==============
  Helper Classes
===================*/


.w-30{
  max-width: 30px;
}
.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}

.l-h-23{
  line-height: 23px;
}
.l-h-34 {
  line-height: 34px;
}

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-24{
  font-size: 24px;
}
.font-30{
  font-size: 30px;
}

.thumb-sm {
  height: 32px;
  width: 32px;
}
.thumb-md {
  height: 48px;
  width: 48px;
}
.thumb-lg {
  height: 88px;
  width: 88px;
}

.font-500{
  font-weight: 500;
}

.font-600{
  font-weight: 600;
}


.header-title {
  font-size: 16px;
}

.bg-pattern{
  background-image: url("../images/bg-pattern.png");
  background-size: cover;
  background-position: bottom;
}