
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap');

// Color variables
$white: #ffffff;

$primary: #0096d6;
$success: #0096d6;
$info: #06c2de;
$warning: #f9d570;
$danger: #e74c5e;
$dark: #2b354f;
$muted: #9ca8b3;


// Gray Colors
$gray-100:                  #f0f4f7;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #616f80;
$gray-700:                  #495057;
$gray-800:                  #343a40;
$gray-900:                  #212529;

$bg-body: #f3f3f3;
$body-color: #616f80;
$left-bar: #ffffff;
$topbar-background: #0096d6;
$topbar-search-background: #f0f4f7;
$footer-background: #ffffff;

// light leftbar

$light-leftbar:                     #ffffff;
$light-leftbar-menu-active-color:   #1e355e;
$topbar-background-dark:            #1e355e;

$font-family: 'Roboto', sans-serif;
$font-family-secondary: 'Rubik', sans-serif;

$shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);

$base-font: 14px;

// Width variables
$width: 100%;

// Height variables
$height: 100%;

$leftbar-menu-color: #666666;
$leftbar-menu-hover-color: #0096d6;
$leftbar-menu-active-color: #0096d6;
