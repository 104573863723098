/* ==============
  Bootstrap-custom
===================*/

//Dropdown
.dropdown-menu {
  padding: 4px 0;
  font-size: $base-font;
  box-shadow: $shadow;
  background-color: $white;
  border-color: $gray-200;
  margin: 0;
}
.dropdown-item {
  background-color: $white;
  padding: .4rem 1rem;
  
  &:active,&:hover {
    background-color: $gray-100;
    color: $dark;
  }
}

.dropdown-item.active, .dropdown-item:active {
  background-color: $gray-100;
  color: $dark;
}

.breadcrumb>li+li:before {
  padding: 0 5px;
  color: $muted;
  content: "\f105" !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid $gray-200;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid $gray-200;
  text-align: right;
}

//Background color
.bg-primary {
  background-color: $primary !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-muted {
  background-color: $muted !important;
}

.bg-white {
  background-color: $white !important;
}

// Background soft color

.bg-lighten-primary {
  background-color: lighten($primary, 32%);
}

.bg-lighten-success {
  background-color: lighten($success, 32%);
}

.bg-lighten-info {
  background-color: lighten($info, 32%);
}

.bg-lighten-warning {
  background-color: lighten($warning, 22%);
}

.bg-lighten-danger {
  background-color: lighten($danger, 32%);
}

//Text Color
.text-white {
  color: $white !important;
}

.text-danger {
  color: $danger !important;
}

.text-muted {
  color: $muted !important;
}

.text-primary {
  color: $primary !important;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $success !important;
}

.text-info {
  color: $info !important;
}

.text-dark {
  color: $dark !important;
}


//Badge

.badge {
  font-weight: 500;
}


.badge-primary {
  background-color: $primary;
}

.badge-success {
  background-color: $success;
}

.badge-info {
  background-color: $info;
}

.badge-warning {
  background-color: $warning;
  color: $white;
}

.badge-danger {
  background-color: $danger;
}

.badge-dark {
  background-color: $dark;
}

// Badge soft
.badge-soft-primary {
  background-color: rgba($primary, 0.2);
  color: $primary;
}

.badge-soft-success {
  background-color: rgba($success, 0.2);
  color: $success;
}

.badge-soft-info {
  background-color: rgba($info, 0.2);
  color: $info;
}

.badge-soft-warning {
  background-color: rgba($warning, 0.2);
  color: $warning;
}

.badge-soft-danger {
  background-color: rgba($danger, 0.2);
  color: $danger;
}

.badge-soft-dark {
  background-color: rgba($dark, 0.2);
  color: $dark;
}

// border

.border-primary{
  border-color: $primary !important;
}

.border-success {
  border-color: $success !important;
}

.border-info {
  border-color: $info !important;
}

.border-warning {
  border-color: $warning !important;
}

.border-danger {
  border-color: $danger !important;
}

.border-dark{
  border-color: $dark !important;
}

// typography

dt {
  font-weight: 500;
}

// spinner

.spinner-border, .spinner-grow {
  margin-right: 10px;
  margin-top: 10px;
}

// checkbox

.custom-control-input{
  &:checked~.custom-control-label{
    &:before {
      border-color: $danger;
      background-color: $danger;
    }
  }
}