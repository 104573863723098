
/* ==============
  Extra pages
===================*/



/******* FAQs ********/ 

.faq-box{
    .faq-icon{
        position: relative;
        i{
            display: inline-block;
            height: 30px;
            width: 30px;
            line-height: 30px;
            border-radius: 50%;
            text-align: center;
        }
        &:after{
            content: "";
            position: absolute;
            top: 0;
            right: -11px;
            border: 12px solid transparent;
            border-bottom-color: lighten($danger, 32%);
        }
    }
}