@font-face {
    font-family: 'ProximaNovaBold';
    src: url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
         url('../fonts/ProximaNova-Bold.woff') format('woff');
}
@font-face {
    font-family: 'ProximaNovaRegular';
    src: url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
         url('../fonts/ProximaNova-Regular.woff') format('woff');
}
.pn_bold {
    font-family: 'ProximaNovaBold', sans-serif !important;
}
.pn_regular {
    font-family: 'ProximaNovaRegular', sans-serif !important;
}
body, header, footer, p, a, div, td, input, select, button {
    font-family: 'ProximaNovaRegular', sans-serif !important;
}
.card-title, .sorting, .quote-company-name, .menu-title, h1, h2, h3, h4, h5, h6, th {
    font-family: 'ProximaNovaBold', sans-serif !important;
}
.rbd-pc-hero-blue {
    background-color: #3f86ff !important;
}
.rbd-sc-teal {
    background-color: #00cdc0 !important;
}
.rbd-sc-plum {
    background-color: #b566e5 !important;
}
.rbd-sc-grey {
    background-color: #808184 !important;
}
.rbd-sc-silver {
    background-color: #edeff0 !important;
}

/* need to add the bg-color for predefined custom styles in button and badges  */
