.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y {
  width: auto !important;
}

.react-datepicker__header {
  background-color: #ffffff !important;
  border: none;
}
.react-datepicker {
  border: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  border-radius: 2.3rem;
  background-color: #e74c5e;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover {
  border-radius: 2.3rem;
  background-color: #e74c5e;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  border-radius: 1.3rem;
  background-color: #f0f0f0;
}
.rdw-editor-wrapper {
  height: 200px !important;
  overflow: hidden !important;
  border: 1px solid #f1f1f1 !important;
}
.rdw-editor-main {
  overflow: hidden !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #f90000 !important;
  height: 83% !important;
}
.rangeslider-horizontal {
  height: 8px !important;
}
.rangeslider-horizontal .rangeslider__handle {
  border: 1px solid #f90000 !important;
}
.ql-container {
  height: 172px !important;
  border: 1px solid #cccccc !important;
}
.dz-message {
  text-align: center !important;
}
.dataTables_filter {
  float: right;
}
.dataTables_paginate {
  float: right;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker__input-container {
  width: 100% !important;
}
.rdt_TableRow {
  padding: 7px !important;
}

.react-datepicker-popper {
  z-index: 1111 !important;
}

.radial-chart {
  height: 453px;
}

.social-source .apexcharts-canvas {
  top: 22px;
}

.recent-stock .apexcharts-canvas {
  top: 20px;
}

/* ecommerce-products-list */
.ecommerce-product .rdt_TableHeader {
  display: none;
}
.ecommerce-product .rdt_TableHeadRow {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.ecommerce-product .rdt_TableRow {
  padding: 0px !important;
}
.ecommerce-product .bZYBY:nth-child(odd) {
  background-color: #f0f4f7;
}
.ecommerce-product .cPZsbN {
  padding: 15px 12px;
}
.ecommerce-product .kXTIEO,
.ecommerce-product .dJFOdV {
  padding: 0.75rem;
  font-size: 14px;
  color: #212529;
  font-weight: 500;
}

/* eccommerce-order-history */
.ecommerce-order .rdt_TableHeader {
  display: none;
}
.ecommerce-order .rdt_TableHeadRow {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.ecommerce-order .dJFOdV {
  padding: 0.75rem;
}
.ecommerce-order .rdt_TableRow {
  padding: 0px !important;
}
.ecommerce-order .cPZsbN {
  padding: 18px 12px;
}
.ecommerce-order .bZYBY:nth-child(odd) {
  background-color: #f0f4f7;
}
.ecommerce-order .sc-jTzLTM {
  font-size: 14px;
  font-weight: 500;
  color: #212529;
}
.ecommerce-order .cPZsbN .react-data-table--cell-content {
  font-size: 14px;
}
.ecommerce-order .rdt_TableRow .rdt_TableCell:first-child {
  font-weight: 500;
  color: #616f80;
}

/* ecommerce-customers */
.ecommerce-customers .rdt_TableHeader {
  display: none;
}
.ecommerce-customers .rdt_TableHeadRow {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.ecommerce-customers .sc-ifAKCX {
  padding: 0.75rem !important;
}
.ecommerce-customers .rdt_TableRow {
  padding: 0px !important;
}
.ecommerce-customers .cPZsbN {
  padding: 18px 12px !important;
}
.ecommerce-customers .bZYBY:nth-child(odd) {
  background-color: #f0f4f7;
}
.ecommerce-customers .sc-jTzLTM {
  font-size: 14px;
  font-weight: 500;
  color: #212529;
}
.ecommerce-customers .react-data-table--cell-content {
  font-size: 14px;
}

/* .form-advanced-switch */
.form-advanced .react-switch-bg {
  height: 24px !important;
}

.form-advanced .react-switch-bg div div {
  font-size: 12px !important;
  height: 24px !important;
  width: 39px !important;
}
.form-advanced .react-switch-handle {
  height: 20px !important;
  width: 20px !important;
  left: 4px !important;
  top: 0.1rem !important;
}

/* .form-elements-checked-switch */
.react-toggle-track {
  width: 29px;
  height: 16px;
}
.react-toggle-thumb {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  top: 2px;
}
.react-toggle--checked .react-toggle-thumb {
  left: 15px;
  top: 2px;
  border-color: white;
  background-color: white !important;
}
.react-toggle--checked .react-toggle-track {
  background-color: #e74c5e;
}
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none !important;
}
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #e74c5e !important;
}

/* .form-elements-checked-disabled */
.react-toggle--disabled .react-toggle-track {
  background-color: #e9ecef;
  border: 1px solid #727375 !important;
}

.react-toggle--disabled .react-toggle-thumb {
  background-color: #465c72;
  left: 3px;
  top: 2px;
}

/* color-peacker */
.react-color-picker {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

/* file-upload */
.dropzone {
  position: relative;
}
.dz-message {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.fWmJgw {
  overflow: hidden;
}

.icon-success {
  color: #02a499 !important;
}

.icon-danger {
  color: #e74c5e !important;
}

.btn-success {
  background-color: #02a499 !important;
  border: 1px solid #02a499 !important;
}

.btn-success:hover {
  background-color: #06968d !important;
}

.flex-container {
  display: flex;
}

.discount-input {
  width: 60% !important;
  display: inline-block !important;
}

.discount-type-select {
  width: 40% !important;
  display: inline-block !important;
  margin-right: 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.tr-discount td,
.tr-total td,
.tr-qantas-points td,
.tr-qantas-bonus-points td,
.tr-subtotal td {
  border: 0 !important;
}

.tr-total-margin {
  border-top: 2px solid #1b1b1b !important;
  font-weight: 500;
}

.tr-subtotal td {
  border-top: 1px solid #ccc !important;
}

#table-upfront-totals td,
#table-monthly-totals td {
  border: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.form-control[readonly] {
  background: #e7eff7 !important;
}

/* SIDEBAR MENUS */
.quote-builder-menus,
.quote-builder-title {
  background: #0096d6;
  color: #fff !important;
}

.caf-builder-menus,
.caf-builder-title {
  background: #3ca499;
  color: #fff !important;
}

.quote-builder-menus a {
  color: #fff !important;
}

#sidebar-menu > ul > li.quote-builder-menus a.active {
  background: #f3f3f3 !important;
  color: #0096d6 !important;
}

#sidebar-menu > ul > li.quote-builder-menus a.disabled {
  background: #cccc;
  color: #666666 !important;
  cursor: not-allowed;
  position: relative;
}

.accepted-quote-link {
  position: relative;
}

.accepted-quote-link span {
  margin-left: 10px;
}

#sidebar-menu > ul > li > .accepted-quote-link > a {
  color: #666666;
  display: block;
  padding: 13px 20px;
  font-size: 15px;
  position: relative;
  transition: all 0.5s;
  font-family: 'Rubik', sans-serif;
}

.accepted-quote {
  position: absolute !important;
  font-size: 8pt !important;
  right: 5px;
  bottom: 14px;
  cursor: pointer;
  font-weight: 500;
  background: #3ca499;
  padding: 2px 5px !important;
  border-radius: 4px;
}

.accepted-quote:hover {
  filter: brightness(90%);
}

#sidebar-menu > ul > li.quote-builder-menus a span.step-icon {
  margin-left: 0px;
  background: #fff;
  color: #666666;
  width: 18px;
  height: 18px;
  display: inline-block;
  text-align: center;
  border-radius: 100px;
  font-size: 12px;
  position: relative;
  top: -1px;
}

#sidebar-menu > ul > li.quote-builder-menus a.active span.step-icon {
  background: #1e355e;
  color: #fff;
}

#sidebar-menu > ul > li.main-menus > a.mm-active {
  background: #f3f3f3;
  box-shadow: none;
}

.enlarged #wrapper .left.side-menu #sidebar-menu > ul > li.main-menus > a:hover,
.enlarged
  #wrapper
  .left.side-menu
  #sidebar-menu
  > ul
  > li.main-menus
  > a.mm-active:hover {
  background: #0096d6 !important;
  color: #fff !important;
}

.enlarged
  #wrapper
  .left.side-menu
  #sidebar-menu
  > ul
  > li.quote-builder-menus
  > a,
.enlarged
  #wrapper
  .left.side-menu
  #sidebar-menu
  > ul
  > li.quote-builder-menus
  > a
  > span {
  color: #0096d6;
}

.enlarged
  #wrapper
  .left.side-menu
  #sidebar-menu
  > ul
  > li.quote-builder-menus:hover
  > a
  i {
  color: #0096d6;
}

.enlarged
  #wrapper
  .left.side-menu
  #sidebar-menu
  > ul
  > li.quote-builder-menus
  > a.false:hover
  > span,
.enlarged
  #wrapper
  .left.side-menu
  #sidebar-menu
  > ul
  > li.quote-builder-menus
  > a.false:hover
  i {
  color: #fff;
}

.enlarged
  #wrapper
  .left.side-menu
  #sidebar-menu
  > ul
  > li.quote-builder-menus
  > a:hover {
  background: #0096d6;
}

/* DATATABLES STYLES */
.dataTables_length.bs-select,
.dataTables_info,
.dataTables_filter {
  text-align: left;
}

th.sorting,
th.sorting_desc,
th.sorting_asc,
.pagination .page-link {
  cursor: pointer;
}

.table-hover .table-dark:hover {
  background: #41474d !important;
}

.card-header {
  background: #343a40 !important;
  color: #fff;
}

.form-control {
  height: calc(1.5em + 0.75rem + 5px);
}

.table {
  margin-bottom: 0 !important;
}

.card-content .table td {
  vertical-align: baseline !important;
}

.sun-editor {
  width: 100% !important;
}

.sun-editor-editable strong,
.sun-editor-editable b {
  font-weight: 600;
}

.btn-grp-variables .btn:disabled {
  cursor: not-allowed;
}

.btn-grp-variables .btn {
  overflow: hidden;
  white-space: nowrap;
}

/* dropzone */
.dropzone {
  min-height: 240px !important;
}

.dropzone .upload-icon {
  display: flex;
  justify-content: center;
  font-size: 77px;
}

.dropzone .preview-img {
  display: inline-flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}

.dropzone .dropzone-text {
  font-size: 20px;
}

.text-primary {
  color: #007bff;
}

.list-action-menu {
  height: 30px !important;
  border: 0 !important;
  background: transparent !important;
  color: gray !important;
}

.dropdown-item a,
.dropdown-item a:hover {
  color: #212529;
}

.badge-success {
  background-color: #28a745 !important;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 4;
  cursor: not-allowed;
}

.quotes-list-table tr {
  cursor: pointer;
}

.product-search-bar {
  z-index: 1 !important;
  top: 70px !important;
}

.product-search {
  background-color: #fff !important;
  padding: 30px 30px 30px !important;
  margin-left: 270px !important;
  margin-right: 30px;
}

.enlarged .product-search {
  margin-left: 100px !important;
}

@media screen and (max-width: 768px) {
  .enlarged .product-search {
    margin-left: 30px !important;
  }
}

.d-block {
  display: block !important;
}

.container-admin-modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #00000059;
  z-index: 999;
}

.container-admin-modal .admin-modal {
  width: 400px;
  background-color: #fff;
  margin: calc(100vh - 70vh) auto;
  padding: 50px 20px;
  border-radius: 5px;
}

.item-center {
  text-align: center;
}

.admin-password-error {
  padding-left: 20px;
  color: #ff0000c2;
  font-weight: 500;
}

.admin-modal-close {
  text-decoration: underline;
  cursor: pointer;
}
.admin-modal-close:hover {
  text-decoration: underline;
  color: #ff000094;
}

.show-price-container {
  position: relative;
  right: 0;
  text-align: left;
  padding-left: 8px;
}

.show-price-text {
  margin-left: 5px;
  font-size: 8pt;
  color: #484242;
  position: relative;
  top: -1.5px;
}

.display-product-container {
  position: relative;
  right: 0;
  text-align: left;
  padding-left: 8px;
}

.display-product-text {
  margin-left: 5px;
  font-size: 7.9pt;
  color: #484242;
  position: relative;
  top: -1.5px;
}

.include-price-container {
  position: relative;
  right: 0;
  text-align: left;
  padding-left: 8px;
}

.include-price-text {
  margin-left: 5px;
  font-size: 8pt;
  color: #484242;
  position: relative;
  top: -1.5px;
}

.abn-link {
  font-weight: 600;
  color: #0096d6;
  padding: 0;
  margin: 0;
}

.line-total {
  position: relative;
  text-align: right;
  font-size: 9pt;
  padding: 4px 0;
}

.confirmation-modal {
  padding: 20px 0px;
  text-align: center;
  font-size: 12pt;
}

.confirmation-title {
  font-size: 24px;
  display: none;
}

.confirmation-container-image {
  width: 200px;
  height: 250px;
  margin: auto;
}

.confirmation-container-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .card-content {
      padding-top: 50px;
    }
  }
}

/* Safari 10.1 */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    .card-content {
      padding-top: 50px;
    }
  }
}

@supports (overflow: -webkit-marquee) and (justify-content: inherit) {
  .card-content {
    padding-top: 50px;
  }
}

.dashboard-logo-container {
  width: 120px;
  height: 120px;
}

.quote-company-name {
  text-align: center;
  font-size: 10pt;
  font-weight: 500;
}

.quote-description {
  text-align: center;
  font-size: 8pt;
  min-height: 50px;
  font-weight: 400;
}

.quote-body-description {
  font-size: 8pt;
}

table {
  border-collapse: collapse;
}

.products-list-table .table-responsive,
.quotes-list-table .table-responsive-sm {
  max-height: 100vh;
  overflow-y: auto;
}

.products-list-table .table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.products-list-table .table-responsive::-webkit-scrollbar-track {
  background: #cecece;
  margin-bottom: 10px;
  margin-right: 5px;
}

.products-list-table .table-responsive::-webkit-scrollbar-thumb {
  background: #8b8c8c;
}

.products-list-table .table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #8b8c8c;
}

.quotes-list-table .table-responsive-sm::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.quotes-list-table .table-responsive-sm::-webkit-scrollbar-track {
  background: #cecece;
  margin-bottom: 10px;
  margin-right: 5px;
}

.quotes-list-table .table-responsive-sm::-webkit-scrollbar-thumb {
  background: #8b8c8c;
}

.quotes-list-table .table-responsive-sm::-webkit-scrollbar-thumb:hover {
  background-color: #8b8c8c;
}

.products-list-table table thead th {
  padding: 3px;
  position: sticky !important;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: #343a40;
  color: #fff;
}

.quotes-list-table table thead th {
  padding: 10px;
  position: sticky !important;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: #343a40;
  color: #fff;
}

.qoute-item-display {
  max-width: 25% !important;
}

.toggle-product-title {
  position: relative;
  bottom: 3px;
  font-weight: 500;
}

.not-applicable {
  color: red;
  font-style: italic;
}
